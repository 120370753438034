/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import Vue from "vue";
import VueCookies from "vue-cookies-ts";
import instance from "@/axios";
import instanceapplet from "@/axiosapplet";

Vue.use(VueCookies)

class IVue  extends Vue {
  public $post(url: string, data = {}, config = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      instance.post(url, data, config).then(res => {
        if (res.data.code === 200) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      })
    })
  }

  public $postapplet(url: string, data = {}, config = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      instanceapplet.post(url, data, config).then(res => {
        if (res.data.code === 200) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      })
    })
  }

  public $myget(url:string, data={}, config={}): Promise<any>{
    return new Promise((resolve, reject) => {
      instanceapplet.get(url, { params: data }).then(res => {
        if (res.data.code === 200) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      })
    })
  }

  public $elementMessage(msg: string, t: string) {
    switch (t) {
      case 'error':
        this.$message.error(msg)
        break
      default :
        this.$message.success(msg)
        break
    }
  }
}

const bus = new IVue();

export default bus;
