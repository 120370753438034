import Vue from 'vue'
import Vuex from 'vuex'
import state from "@/store/state";

Vue.use(Vuex)

export default new Vuex.Store({
  state: state,
  mutations: {},
  actions: {
    // 设置控件索引
    setControlIndex({state}, index) {
      state.controlIndex = index
    },

    // 添加控件
    addControl({state}, data) {
      state.controlList.push(data)
    },

    // 清空
    empty({state}) {
      state.controlList = []
    },

    // 设置控件类型
    setControlType({state}, data) {
      state.controlType = data
    },

    // 删除动画
    deleAnimation({state}) {
      state.controlList[state.controlIndex].animation.animationClassName = ""
      state.controlList[state.controlIndex].animation.animationName = ""
    },

    // 获取数据
    setControlList({state}, data) {

      state.controlList = data ? JSON.parse(data) : []
      if (JSON.parse(data) instanceof Array) {
        state.controlList.forEach(res => {
          res.infoX = res.coLeftPosition
          res.infoY = res.coRightPosition
          res.infoWidth = res.coWidth
          res.infoHeight = res.coHeight
        })
      } else {
        state.controlList = []
      }
    }
  },
  modules: {}
})
