
































import bus from "@/bus";
import {Component, Vue} from "vue-property-decorator";

@Component
export default class IHeader extends Vue {
  public dropout() {
    bus.$cookies.remove('Token');
    this.$router.replace("/login")
  }
}
