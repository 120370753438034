import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import "@/assets/scss/app.scss"
import "@/assets/scss/initialize.scss"
import "vue-draggable-resizable-gorkys/dist/VueDraggableResizable.css"
import 'animate.css'
import vcolorpicker from 'vcolorpicker'
import bus from "@/bus";

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(vcolorpicker)


router.beforeEach((to, from, next) => {
  const token = bus.$cookies.get('Token')

  if (!token && to.name !== "Login" && to.name !== 'h5' && to.name !== 'preview'&& to.name !== 'h5user') {
    next("/login")
  } else {
    next()
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
