import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: "/user-list",
    children: [
      {
        path: "/user-list",
        name: "UserList",
        component: () => import('@/views/UserList.vue')
      },
      {
        path: "/temp-list",
        name: "tempList",
        component: () => import('@/views/TempList.vue')
      }, {
        path: "/material",
        name: "material",
        component: () => import('@/views/Material.vue')
      }, {
        path: "/device-list",
        name: "DeviceList",
        component: () => import('@/views/DeviceList.vue')
      }
    ]
  },
  {
    path: "/h5/:cid/:type",
    name: "h5",
    component: () => import("@/views/H5.vue")
  },
  {
    path: "/preview/:cid/:type",
    name: "preview",
    component: () => import("@/views/Preview.vue")
  },
  {
    path: "/h5-user/:cid/:type",
    name: "h5user",
    component: () => import("@/views/H5User.vue")
  },
  {
    path: "/edit-temp",
    name: "EditTemp",
    component: () => import("@/views/EditTemp.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
