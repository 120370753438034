import axios from "axios"
import VueRouter from "./router/index"
import bus from "@/bus";

const instance = axios.create({
    baseURL: '/esoncloudh5/api',
    timeout: 10000000,
});

//请求拦截器
instance.interceptors.request.use((config) => {
    const Token = bus.$cookies.get("appToken")
    config.headers.Token = "Bearer " + Token
    return config
}, (error) => {
    return Promise.reject(error)
})

//响应拦截器
instance.interceptors.response.use((response) => {
    if (response.data.code === 403) {
        VueRouter.replace("/login")
    }
    return response
}, (error) => {
    return Promise.reject(error)
})

export default instance;
