









import {Component, Vue} from 'vue-property-decorator';
import IHeader from "@/components/IHeader.vue";

@Component({
  components: {
    IHeader
  }
})
export default class Home extends Vue {
}
