interface States {
  controlType: string,
  controlIndex: number,
  animationList: Array<any>,
  controlList: Array<any>
}

const state: States = {
  controlType: "",
  controlIndex: 0,
  animationList: [],
  controlList: []
}

export default state
